<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} State</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="State Name*"
                  outlined
                  dense :error="$v.state.name.$error"
                  v-model="state.name">
              </v-text-field>
              <span class="text-danger" v-if="$v.state.name.$error">State Name is Required</span>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="State Prefix*"
                  outlined :error="$v.state.prefix.$error"
                  dense
                  v-model="state.prefix">
              </v-text-field>
              <span class="text-danger" v-if="errors.prefix" >{{errors.prefix[0]}}</span>
              <span class="text-danger" v-if="$v.state.prefix.$error">State Prefix is Required</span>
            </v-col>
            <v-col cols="12" md="12" >
              <v-autocomplete
                  v-model="state.country_id"
                  :items="countries" filled
                  outlined :error="$v.state.prefix.$error"
                  dense color="blue-grey lighten-2"
                  label="Select Country"
                  item-text="name"
                  item-value="id">

              </v-autocomplete>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import StateService from "@/services/setting/state/StateService";
import CountryService from "@/services/country/CountryService";
import {required} from "vuelidate/lib/validators";

const country=new CountryService();
const state =new StateService();
export default {
  validations:{
    state:{
      name:{required},
      prefix:{required},

    },
  },
  data() {
    return {
      title: 'Create',
      dialog: false,
      edit: false,
      loading: false,
      countries:[],
      errors:[],
      state:{
        name:'',
        prefix:'',
        country_id:14,
     },
    }
  },
  methods: {
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {

          })
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.state={
        name:'',
        prefix:'',
        country_id:14,
      };
    },
    createState() {

      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
    },
    editState(item) {
      this.openDialog();
      this.edit = true;
      this.state = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      state
          .create(this.state)
          .then(response => {
            this.$snotify.success("State added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      state
          .update(this.state.id, this.state)
          .then(response => {
            this.$snotify.success("State updated");
            this.closeDialog();
            this.resetForm();
            this.errors = [];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },

    stateDetail() {
      if(this.stateId != null || this.stateId !=undefined ){
        state
            .show(this.stateId)
            .then(response=> {

              this.state = response.data.state;

            })
      }
    }
  },
  mounted() {
    this.getCountries();
    this.stateDetail();
  },
  computed:{
    stateId () {
      return this.$route.params.stateId;
    },
    titleText(){
      if(this.stateId!=null || this.stateId!=undefined)
        return this.title="Edit";
      else
        return this.title="Create New"
    }
  }
}
</script>